import React from 'react'
import './PageContainer.scss'

const PageContainer = ({ children, content, nodark }) => {
  return (
    <div
      className="page-container"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay="100"
      data-sal-easing="ease"
    >
      <div className={`col order-2 lg:order-1 left ${nodark ? 'nodark' : ''}`}>
        <div
          className={`container h-full flex py-10 lg:p-12 lg:pb-12  2xl:p-16 w-full`}
        >
          {children}
        </div>
      </div>
      <div
        className={`col image bg-black order-1 lg:order-2 ${
          nodark ? 'nodark' : ''
        }`}
      >
        {!nodark && <div className="bg-overlay"></div>}
        {content}
      </div>
    </div>
  )
}

export default PageContainer
