import React from "react";
import Layout from "../components/Layout/Layout";
import PageContainer from "../components/PageContainer/PageContainer";
import FluidImage from "../components/FluidImage/FluidImage";
import List from "../components/List/List";
import ListItem from "../components/List/ListItem";
import Heading from "../components/PageContainer/Heading";
import Seo from "../components/seo";
import { graphql } from "gatsby";

const oppettider = ({ data: { page } }) => {
  const { text, title, image } = page.header_twocol.header;
  const oppettider = page.oppettider.openingHour;
  return (
    <Layout>
      <Seo
        title={title}
        description={text}
        image={image?.localFile?.childImageSharp.original?.src}
      />
      <PageContainer
        content={<FluidImage src={image?.localFile} title={title} />}
      >
        <div className="flex flex-col gap-6 w-full ">
          <Heading title={title} text={text} />
          <List>
            {oppettider.map((item, index) => (
              <ListItem key={index} first={item.day} second={item.time} />
            ))}
          </List>
        </div>
      </PageContainer>
    </Layout>
  );
};

export default oppettider;

export const pageQuery = graphql`
  query OpeningHoursPage {
    page: wpPage(title: { eq: "Öppettider" }) {
      id
      header_twocol {
        header {
          text
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      oppettider {
        openingHour {
          day
          time
        }
      }
    }
  }
`;
