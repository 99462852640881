import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FluidImage = ({ src, title, contain, hscreen }) => {
  const image = getImage(src);
  return (
    <div className="h-full w-full">
      <GatsbyImage
        image={image}
        alt={title}
        style={{ width: "100%", height: hscreen ? "100vh" : "100%" }}
        imgStyle={{
          objectFit: `${contain ? "contain" : "cover"}`,
          backgroundPosition: "top",
          objectPosition: "top",
        }}
      />
    </div>
  );
};

export default FluidImage;
