import React from "react";
import parse from "html-react-parser";

const Heading = ({ title, text, published, centerXs }) => {
  return (
    <div
      className={`flex flex-col items-start ${
        centerXs ? "text-center  items-center " : ""
      }`}
    >
      {published && (
        <time className="text-base text-white  mb-2">
          Publicerad: {published}
        </time>
      )}
      {title && <h1 className={`h2`}>{title}</h1>}
      {text && (
        <div className="max-w-2xl text-white text-lg">{parse(text)}</div>
      )}
    </div>
  );
};

export default Heading;
